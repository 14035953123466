import React from "react"
import A from "../images/a1000.png"
import B from "../images/b1000.png"
import CC from "../images/cb1000.png"
import SeeMore from "../images/seeMoreButton.png"

import "./static/styles/eventsComponent.css"

const divStyles = {
  // color: `#f6f6f6`,
  // backgroundColor: `#9C27B0`,
  alignItems: `center`,
  backgroundColor: `#f6f6f6`,
  display: `grid`,
  gridTemplateRows: `auto`,
  justifyItems: `center`,
  paddingBottom: `1.3rem`,
  rowGap: `0.4rem`,
}

const eventStyles = {
  display: `grid`,
  justifyItems: `center`,
}

const imgStyles = {
  borderRadius: `1rem`,
  padding: `0.5rem`,
}

const eventDescription = {
  textAlign: `center`,
  padding: `0.5rem`,
}

export default function eventsWorkshops() {
  return (
    <div style={divStyles}>
      <div>
        <h5
          style={{
            textAlign: "center",
            margin: "1rem",
            fontWeight: `bold`,
          }}
          className="headlineStyle_eventsComp"
        >
          EVENTS & WORKSHOPS
        </h5>
        <span style={eventStyles}>
          <a href="/events">
            {" "}
            <img
              src={A}
              style={imgStyles}
              alt="Annie Desby"
              className="events_comp_img"
            />{" "}
          </a>
          <p style={eventDescription}>
            <span
              style={{ WebkitTextEmphasisStyle: `circle` }}
              className="subHeadlineStyle__eventsComp"
            >
              Yoga With Annie
            </span>
            <br />
            <span className="spanStyle__eventsComp">
              ~ 5 Weekly Beginners Course
            </span>
          </p>
        </span>
      </div>
      <span style={eventStyles}>
        <a href="/events">
          {" "}
          <img
            src={B}
            style={imgStyles}
            alt="Meditation Classes"
            className="events_comp_img"
          />{" "}
        </a>
        <p style={eventDescription}>
          <span
            style={{ WebkitTextEmphasisStyle: `triangle` }}
            className="subHeadlineStyle__eventsComp"
          >
            Learn To Meditate w Lorea & Pascal
          </span>
          <br />
          <span className="spanStyle__eventsComp">~ 8 Weekly Course</span>
        </p>
      </span>
      <span style={eventStyles}>
        <a href="/events">
          {" "}
          <img
            src={CC}
            style={imgStyles}
            alt="Advanced Yoga with Emilia Rowe"
            className="events_comp_img"
          />{" "}
        </a>
        <p style={eventDescription}>
          <span
            style={{ WebkitTextEmphasisStyle: `circle` }}
            className="subHeadlineStyle__eventsComp"
          >
            Advanced Yoga Series w Emilia Rowe
          </span>
          <br />{" "}
          <span className="spanStyle__eventsComp"> ~ 4 Weekly Course</span>
        </p>
      </span>
      <a href="/events">
        <img src={SeeMore} alt="See More Button" className="eventsButton" />
      </a>
    </div>
  )
}
