import React from "react"
import A from "../images/a1000x500.png"
import B from "../images/b1000x500.png"
import C from "../images/cb1000x500.png"
import D from "../images/da1000x500.png"

import "./static/styles/slideshow.css"

class Slideshow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      arr: [B, C, D, A],
      index: 0,
    }
  }

  componentDidMount() {
    this.timerID = setInterval(() => {
      this.rotater()
    }, 2500)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  rotater() {
    let initVal = this.state.index

    if (initVal > 2) {
      this.setState({
        index: 0,
      })
    } else {
      this.setState({
        index: this.state.index + 1,
      })
    }
  }

  testMessage() {
    console.log("once every 2 seconds?")
  }

  render() {
    return (
      <div>
        <img
          src={this.state.arr[this.state.index]}
          alt="slideshow"
          className="slideshowImg"
        />
      </div>
    )
  }
}

export default Slideshow
