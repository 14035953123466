import React from "react"
import DA from "../images/da1000.png"
import DB from "../images/db1000.png"
import DC from "../images/dc1000.png"

import "./static/styles/trainTComponent.css"

const divStyles = {
  // color: `#f6f6f6`,
  // backgroundColor: `#FFC107`,
  // backgroundColor: `#f6f6f6`,
  display: `grid`,
  gridTemplateRows: `auto`,
  justifyItems: `center`,
  alignItems: `center`,
  alignContent: `center`,
  gridGap: `1rem`,
  marginTop: `1rem`,
}

const eventStyles = {
  display: `grid`,
  justifyItems: `center`,
}

const imgStyles = {
  borderRadius: `1rem`,
  padding: `0.5rem`,
}

const paraDescription = {
  textAlign: `center`,
  padding: `0.5rem`,
  margin: `unset`,
}

export default function TrainTeachers() {
  return (
    <div style={divStyles}>
      <h5
        style={{
          textAlign: "center",
          margin: "1rem",
          fontWeight: `bold`,
        }}
        className="headlineStyleTeachersComp"
      >
        TEACHER TRAININGS
      </h5>
      <span style={eventStyles}>
        <a href="/trainTeachers">
          {" "}
          <img
            src={DB}
            style={imgStyles}
            alt="Teacher Training w Annie Desby"
            className="trainT_comp_img"
          />{" "}
        </a>
        <p style={paraDescription}>
          <span
            style={{ WebkitTextEmphasisStyle: `circle` }}
            className="subHeadlineStyle__teachersComp"
          >
            Teacher Training 200 hour (3mth track)
          </span>
          <br />
          <span className="spanStyle__teachersComp">
            ~ Yoga Alliance Approved
          </span>
        </p>
      </span>

      <span style={eventStyles}>
        <a href="/trainTeachers">
          {" "}
          <img
            src={DC}
            style={imgStyles}
            alt="Teacher Training w Annie Desby"
            className="trainT_comp_img"
          />{" "}
        </a>
        <p style={paraDescription}>
          <span
            style={{ WebkitTextEmphasisStyle: `circle` }}
            className="subHeadlineStyle__teachersComp"
          >
            Teacher Training 200 hour (6mth track)
          </span>
          <br />
          <span className="spanStyle__teachersComp">
            ~ Yoga Alliance Approved
          </span>
        </p>
      </span>
      <span style={eventStyles}>
        <a href="/trainTeachers">
          {" "}
          <img
            src={DA}
            style={imgStyles}
            alt="Teacher Training w Annie Desby"
            className="trainT_comp_img"
          />{" "}
        </a>
        <p style={paraDescription}>
          <span
            style={{ WebkitTextEmphasisStyle: `circle` }}
            className="subHeadlineStyle__teachersComp"
          >
            Teacher Training 200 hour (12mth track)
          </span>
          <br />
          <span className="spanStyle__teachersComp">
            ~ Yoga Alliance Approved
          </span>
        </p>
      </span>
    </div>
  )
}
