import React from "react"
import Logo from "../images/logo_95b.png"
import Slideshow from "./slideshow"

const divStyles = {
  alignItems: `center`,
  borderRadius: `0.5rem`,
  color: `#f6f6f6`,
  // backgroundColor: `aqua`,
  display: `grid`,
  justifyItems: `center`,
  gridTemplateRows: `auto`,
  gridTemplateColumns: `1fr`,
  padding: `0.3rem`,
}

const logoStyles = {
  marginBottom: `2rem`,
}

export default function logoSlideshow() {
  return (
    <div style={divStyles}>
      <span>
        <img src={Logo} style={logoStyles} alt="Logo" />
      </span>
      <span>
        <Slideshow />
      </span>
    </div>
  )
}
