import React from "react"
import TimetableButton from "../images/timetableButton.png"
import A from "../images/da1000x500.png"

import "./static/styles/classesComponent.css"

const divStyles = {
  // color: `#f6f6f6`,
  // backgroundColor: `#8BC34A`,
  alignItems: `center`,
  alignContent: `center`,
  borderRadius: `0.5rem`,
  display: `grid`,
  gridTemplateRows: `auto`,
  gridTemplateColumns: `1fr`,
  justifyItems: `center`,
  marginTop: `1rem`,
  rowGap: `1rem`,
}

const paraStyles = {
  textAlign: `justify`,
  margin: `unset`,
}

export default function Classes() {
  return (
    <div style={divStyles}>
      <h2 className="headlineStyle__classesComp">CLASSES</h2>
      <img src={A} alt="Current Offer" className="classes_comp_img" />
      <p style={paraStyles} className="paraStyles">
        Something to suit everyone - from one on one sessions, to small or big
        groups. For beginners, one-off classes are a great way to sample the fun
        vibes at our studio! If you can't find something you're interested in,
        just drop us a message and we will get back to you pronto! :)
      </p>
      <a href="/timetable">
        <img
          src={TimetableButton}
          alt="Click for Timetable"
          className="timetableButton"
        />
      </a>
    </div>
  )
}
