import React from "react"
import Insta from "../images/instaTest.jpg"
import A from "../images/f300x375.png"
import B from "../images/g300x375.png"
import C from "../images/h300x375.png"

import "../components/static/styles/mediaComp.css"

const divStyles = {
  color: `#FF5722`,
  backgroundColor: `#f6f6f6`,
  textAlign: `center`,
  width: `87%`,
}

const sectionTitleStyles = {
  padding: `1.5rem 0.5rem 0.5rem 1.5rem`,
  lineHeight: `2.3rem`,
}

const instaDivStyles = {
  marginTop: `1rem`,
}

const instaPicStyles = {
  borderRadius: `0.5rem`,
  height: `auto`,
  margin: `0.3rem`,
  width: `135px`,
}

export default function Media() {
  return (
    <div style={divStyles}>
      <h2 style={sectionTitleStyles} className="headlineStyles__MediaComp">
        FIND US ON INSTAGRAM
      </h2>
      <span id="instaWrapper" style={instaDivStyles}>
        <img src={A} alt="instagram" style={instaPicStyles} />
        <img src={B} alt="instagram" style={instaPicStyles} />
        <img src={C} alt="instagram" style={instaPicStyles} />
        <img src={Insta} alt="instagram" style={instaPicStyles} />
      </span>
    </div>
  )
}
