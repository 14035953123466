import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Main from "../components/main"

import sideImageL from "../images/siderailimg.png"
import sideImageR from "../images/siderailimg.png"
import "../components/static/styles/main.css"

const bigPapaStyle = {
  margin: `0 auto`,
  // maxWidth: `1440px`,
  minHeight: `20rem`,
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={bigPapaStyle} className="bigPapaStyle">
      <div className="main">
        <img src={sideImageL} alt="sidebanner" className="sideImageL" />
        <Main />
        <img src={sideImageR} alt="sidebanner" className="sideImageR" />
      </div>
    </div>
  </Layout>
)

export default IndexPage
