import React, { Component } from "react"
import LogoSlideShow from "./logoSlideshow"
import EventsWorkshops from "./eventsWorkshops"
import Classes from "./classes"
import Training from "./trainTeachers"
import Products from "./products"
import Media from "./media"

import "./static/styles/mainComp.css"

const divStyle = {
  marginTop: `4rem`,
  display: `grid`,
  gridTemplateRows: `auto`,
  gridTemplateColumns: `auto`,
  margin: `auto`,
  justifyItems: `center`,
  alignItems: `center`,
  rowGap: `1rem`,
}

class Main extends Component {
  render() {
    return (
      <div style={divStyle} className="mainComp_divStyle">
        <LogoSlideShow />
        <EventsWorkshops />
        <Classes />
        <Training />
        {/* <Products /> */}
        <Media />
      </div>
    )
  }
}

export default Main
